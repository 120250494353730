<template>
  <div>
    <ModalEditOptionBase
      v-if="option.SupplierType === 'Channel'"
      :showModal="showEditOptionBase"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      additionalClass="mb-5"
      :showEdit="option.SupplierType === 'Channel'"
      :editFunction="setShowEditOptionName"
    >
      <template v-slot:title>{{ option.Name }}</template>
    </ui-section-header>

    <table class="table is-fullwidth">
      <tbody>
        <tr>
          <td>Max amount</td>
          <td>{{ textMaxAmount }}</td>
        </tr>
        <tr>
          <td>Minimum duration</td>
          <td>{{ textMinParticipants }}</td>
        </tr>
        <tr>
          <td>Maximum duration</td>
          <td>{{ textMaxDuration }}</td>
        </tr>
        <tr>
          <td>Minimum participants</td>
          <td>{{ textMinDuration }}</td>
        </tr>
        <tr>
          <td>Maximum participants</td>
          <td>{{ textMaxParticipants }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    ModalEditOptionBase: () =>
      import('@/components/Options/ModalEditOptionBase'),
  },

  props: {},

  data() {
    return {
      showEditOptionBase: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    textMaxAmount() {
      return this.option.MaxAmount ? this.option.MaxAmount : 'Not applicable'
    },

    textMinParticipants() {
      return this.option.MinimumParticipants > 0
        ? this.option.MinimumParticipants
        : 'Not applicable'
    },

    textMaxParticipants() {
      return this.option.MaximumParticipants > 0
        ? this.option.MaximumParticipants
        : 'Not applicable'
    },

    textMinDuration() {
      return this.option.MinimumReservationDuration > 0
        ? this.option.MinimumReservationDuration / 60
        : 'Not applicable'
    },

    textMaxDuration() {
      return this.option.MaximumReservationDuration > 0
        ? this.option.MaximumReservationDuration / 60
        : 'Not applicable'
    },
  },

  created() {},

  methods: {
    setShowEditOptionName() {
      this.showEditOptionBase = true
    },

    hideModal() {
      this.showEditOptionBase = false
    },
  },
}
</script>

<style></style>
